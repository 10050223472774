import "./main.css";
import ProgressBar from 'react-bootstrap/ProgressBar'
export const ResumeGUEZZI = (props) => {
  return (
    <div class="footer-parallax-container"> 
    <div class="page-content">
      <div>
<div class="profile-page">
  <div class="wrapper">
    <div class="page-header page-header-small" filter-color="green">
      <img class="page-header-image"src='https://www.publicdomainpictures.net/pictures/330000/velka/production-technology-1585074891m5L.jpg'  alt="Image"></img>
      <div class="container">
        <div class="content-center">
          <div class="cc-profile-image"><a href="#"><img src="./images/adil.webp" alt="Image"/></a></div>
          <div class="h2 title">Adil GUEZZI</div>
          <p class="category text-white">Full stack JS Developper (Angular / React / Nodejs)</p>
          <a class="btn btn-primary" href="https://drive.google.com/file/d/10l_W6oak78wOi9HFxaUUgHo-nC1we-iu/view?usp=sharing"  target="_blank" data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Download CV</a>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="button-container">
            
            <a class="btn btn-default btn-round btn-lg btn-icon" rel="noreferrer" href="https://github.com/adilyop"  target="_blank" rel="tooltip" title="Check my Github">
              <i class="fa fa-github"></i>
            </a>
            
            <a class="btn btn-default btn-round btn-lg btn-icon" rel="noreferrer" href="https://www.linkedin.com/in/adil-guezzi-a877b885/"  target="_blank" rel="tooltip" title="Check my Linked In">
              <i class="fa fa-linkedin"></i>
            </a>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section" id="about">
  <div class="container">
    <div class="card">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="card-body">
            <div class="h4 title">About Me</div>
            <p>Enthusiastic and detail-oriented JavaScript Developer with a passion for creating efficient, 
              scalable, and maintainable web applications. With over [number] years of hands-on experience 
              in front-end and back-end development, I thrive in dynamic and collaborative environments.
            </p>


            <div class="h4 title">Key Strengths</div>
            <p>
              • <b>Full Stack Proficiency:</b> Proficient in both front-end (Angular, React, React-native) and back-end (Node.js, Express) development, allowing me to create seamless and integrated web solutions.<br />
              • <b>Problem Solver:</b> Adept at identifying challenges and implementing effective solutions to optimize code performance and enhance user experiences.
            </p>


            <div class="h4 title">Professional Philosophy</div>
            <p>
              I am committed to writing clean, modular code that not only meets project requirements but also facilitates future scalability and maintenance. Embracing industry best practices and staying updated on the latest technologies, I aim to bring innovation to every project I undertake.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="card-body">
            <div class="h4 mt-0 title">Basic Information</div>
            <div class="row">
              <div class="col-sm-4"><strong class="text-uppercase">Age:</strong></div>
              <div class="col-sm-8">33</div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-4"><strong class="text-uppercase">Email:</strong></div>
              <div class="col-sm-8">guezziadil@gmail.com</div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-4"><strong class="text-uppercase">Phone:</strong></div>
              <div class="col-sm-8">+ 33 (0) 6 507 974 48</div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-4"><strong class="text-uppercase">Address:</strong></div>
              <div class="col-sm-8">Paris, France</div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-4"><strong class="text-uppercase">Language:</strong></div>
              <div class="col-sm-8">English, French, Arabic</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="section" id="skill">
  <div class="container">
    <div class="h4 text-center mb-4 title">Professional Skills</div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div><div class="space-between"><span class="progress-badge">HTML CSS Javascript</span>
              <span class="progress-value">90%</span> </div>
              <div class="progress">
                <ProgressBar class="progress-bar-primary" variant="success" now={90} />
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div><div class="space-between"><span class="progress-badge">Angular Typescript</span>
              <span class="progress-value">90%</span> </div>
              <div class="progress">
                <ProgressBar class="progress-bar-primary" variant="success" now={90} />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div><div class="space-between"><span class="progress-badge">React native</span>
              <span class="progress-value">80%</span> </div>
              <div class="progress">
                <ProgressBar class="progress-bar-primary" variant="success" now={80} />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div><div class="space-between"><span class="progress-badge">React NodeJS</span>
              <span class="progress-value">75%</span> </div>
              <div class="progress">
                <ProgressBar class="progress-bar-primary" variant="success" now={75} />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div><div class="space-between"><span class="progress-badge">GIT</span>
              <span class="progress-value">90%</span> </div>
              <div class="progress">
                <ProgressBar class="progress-bar-primary" variant="success" now={90} />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div><div class="space-between"><span class="progress-badge">DevOps</span>
              <span class="progress-value">70%</span> </div>
              <div class="progress">
                <ProgressBar class="progress-bar-primary" variant="success" now={70} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* <div class="section" id="portfolio">
  <div class="container">
    <div class="row center-div">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="h4 text-center mb-4 title">Portfolio</div>
        <div class="nav-align-center">
          <ul class="nav nav-pills nav-pills-primary" role="tablist">
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#web-development" role="tablist"><i class="fa fa-laptop" aria-hidden="true"></i></a></li>
            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#graphic-design" role="tablist"><i class="fa fa-picture-o" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tab-content gallery mt-5">
      <div class="tab-pane active" id="web-development">
        <div class="ml-auto mr-auto">
          <div class="row">
            <div class="col-md-6">
              <div class="cc-porfolio-image img-raised"><a href="#web-development">
                  <figure class="cc-effect"><img src="./images/project1.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Recent Project</div>
                      <p>Web Development</p>
                    </figcaption>
                  </figure></a></div>
              <div class="cc-porfolio-image img-raised"><a href="#web-development">
                  <figure class="cc-effect"><img src="./images/project-2.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Startup Project</div>
                      <p>Web Development</p>
                    </figcaption>
                  </figure></a></div>
            </div>
            <div class="col-md-6">
              <div class="cc-porfolio-image img-raised"><a href="#web-development">
                  <figure class="cc-effect"><img src="./images/project-3.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Food Order Project</div>
                      <p>Web Development</p>
                    </figcaption>
                  </figure></a></div>
              <div class="cc-porfolio-image img-raised"><a href="#web-development">
                  <figure class="cc-effect"><img src="./images/project-4.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Web Advertising Project</div>
                      <p>Web Development</p>
                    </figcaption>
                  </figure></a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="graphic-design" role="tabpanel">
        <div class="ml-auto mr-auto">
          <div class="row">
            <div class="col-md-6">
              <div class="cc-porfolio-image img-raised"><a href="#graphic-design">
                  <figure class="cc-effect">
                    <img src="./images/graphic-design-1.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Triangle Pattern</div>
                      <p>Graphic Design</p>
                    </figcaption>
                  </figure>
                  </a>
                  </div>
              <div class="cc-porfolio-image img-raised"><a href="#graphic-design">
                  <figure class="cc-effect"><img src="./images/graphic-design-2.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Abstract Umbrella</div>
                      <p>Graphic Design</p>
                    </figcaption>
                  </figure></a></div>
            </div>
            <div class="col-md-6">
              <div class="cc-porfolio-image img-raised"><a href="#graphic-design">
                  <figure class="cc-effect"><img src="./images/graphic-design-3.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Cube Surface Texture</div>
                      <p>Graphic Design</p>
                    </figcaption>
                  </figure></a></div>
              <div class="cc-porfolio-image img-raised"><a href="#graphic-design">
                  <figure class="cc-effect"><img src="./images/graphic-design-4.jpg" alt="Image"/>
                    <figcaption>
                      <div class="h4">Abstract Line</div>
                      <p>Graphic Design</p>
                    </figcaption>
                  </figure></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
<div class="section" id="experience">
  <div class="container cc-experience">
    <div class="h4 text-center mb-4 title">Work Experience</div>
    <div class="card">
      <div class="row">
        <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body cc-experience-header">
            <p>February 2022 - Present</p>
            <div class="h5">Servier Monde</div>
          </div>
        </div>
        <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body">
            <div class="h5">Lead Dev Angular</div>
            <p>Responsive Application Development for Servier Employees</p>
            <p>Environnement: VS Code, Azure devops, JIRA, outlook, teams, angular 11/17, git, cypress, React, storybook and powerApps</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body cc-experience-header">
            <p>November 2019 - Junary 2022</p>
            <div class="h5">Citeo</div>
          </div>
        </div>
        <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body">
            <div class="h5">Angular Developer</div>
            <p>Portal Development for Diverse User Types with Access Management and Authorization</p>
            <p>Environnement: Webstorm, Azure devops, JIRA, outlook, teams, angular 5/14, git, cypress, Stencil, storybook</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body cc-experience-header">
            <p>December 2017 - November 2019</p>
            <div class="h5">Carestream Dental</div>
          </div>
        </div>
        <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body">
            <div class="h5">Angular Developer</div>
            <p>Development of Web Applications for Review Computers and Integrated Radiology Machine Screens with Synchronized Navigation and Actions</p>
            <p>Environnement: Pycharm, Jenkins, Targuet process, outlook, Slack, angular 1/8, git</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="row">
        <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body cc-experience-header">
            <p>November 2015 - November 2017</p>
            <div class="h5">Taillis Labs</div>
          </div>
        </div>
        <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body">
            <div class="h5">Full stack JS developper React & nodejs</div>
            <p>Full Stack Development of Web and Hybrid Mobile VoIP Applications from Scratch</p>
            <p>Environnement:  NodeJS, React-native, Redux, React, Express.JS, MongoDB, CouchDB, Trello, Git, Docker,
                Nginx, AWS, RabbitMq, VolP, FreeSwitch, Kamailio, Kazoo, tshark, ngrep Grafana, Haproxy, iptables, firewalling, Mocha, Chai, WebRTC, Temasys, socket.io, npm, Yarn, Babel, ESLint, VS Code, Bilforward, Dwolla, Paypal, Braintree, Authorize. Net, Swagger, Firebase, OneSignal, Passport, JWT, Auth0, Echosign</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section">
  <div class="container cc-education">
    <div class="h4 text-center mb-4 title">Education</div>
    <div class="card">
      <div class="row">
        <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body cc-education-header">
            <p>2011 - 2014</p>
            <div class="h5">Engineer's Degree</div>
          </div>
        </div>
        <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body">
            <div class="h5">Information Technology Engineer</div>
            <p class="category">INPT Institute of Computer Science</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body cc-education-header">
            <p>2009 - 2011</p>
            <div class="h5">Preparatory classes </div>
          </div>
        </div>
        <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body">
            <div class="h5">Bachelor of Mathematics and physics</div>
            <p class="category">University of Computer Science</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body cc-education-header">
            <p>2006 - 2009</p>
            <div class="h5">High School</div>
          </div>
        </div>
        <div class="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div class="card-body">
            <div class="h5">Science and Mathematics</div>
            <p class="category">Technical High School</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* <div class="section" id="reference">
  <div class="container cc-reference">
    <div class="h4 mb-4 text-center title">References</div>
    <div class="card" data-aos="zoom-in">
      <div class="carousel slide" id="cc-Indicators" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item">
            <div class="row flex-display">
              <div class="col-lg-2 col-md-3 cc-reference-header"><img src="./images/reference-image-1.jpg" alt="Image"/>
                <div class="h5 pt-2">Aiyana</div>
                <p class="category">CEO / WEBM</p>
              </div>
              <div class="col-lg-10 col-md-9">
                <p> Habitasse venenatis commodo tempor eleifend arcu sociis sollicitudin ante pulvinar ad, est porta cras erat ullamcorper volutpat metus duis platea convallis, tortor primis ac quisque etiam luctus nisl nullam fames. Ligula purus suscipit tempus nascetur curabitur donec nam ullamcorper, laoreet nullam mauris dui aptent facilisis neque elementum ac, risus semper felis parturient fringilla rhoncus eleifend.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row flex-display">
              <div class="col-lg-2 col-md-3 cc-reference-header"><img src="./images/reference-image-2.jpg" alt="Image"/>
                <div class="h5 pt-2">Braiden</div>
                <p class="category">CEO / Creativem</p>
              </div>
              <div class="col-lg-10 col-md-9">
                <p> Habitasse venenatis commodo tempor eleifend arcu sociis sollicitudin ante pulvinar ad, est porta cras erat ullamcorper volutpat metus duis platea convallis, tortor primis ac quisque etiam luctus nisl nullam fames. Ligula purus suscipit tempus nascetur curabitur donec nam ullamcorper, laoreet nullam mauris dui aptent facilisis neque elementum ac, risus semper felis parturient fringilla rhoncus eleifend.</p>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row flex-display">
              <div class="col-lg-2 col-md-3 cc-reference-header"><img src="./images/reference-image-3.jpg" alt="Image"/>
                <div class="h5 pt-2">Alexander</div>
                <p class="category">CEO / Webnote</p>
              </div>
              <div class="col-lg-10 col-md-9">
                <p> Habitasse venenatis commodo tempor eleifend arcu sociis sollicitudin ante pulvinar ad, est porta cras erat ullamcorper volutpat metus duis platea convallis, tortor primis ac quisque etiam luctus nisl nullam fames. Ligula purus suscipit tempus nascetur curabitur donec nam ullamcorper, laoreet nullam mauris dui aptent facilisis neque elementum ac, risus semper felis parturient fringilla rhoncus eleifend.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
<div class="section">
  <div class="cc-contact-information" style={{backgroundImage: ('./images/staticmap.png')}}>
    <div class="container">
      <div class="cc-contact">
        <div class="row">
          <div class="col-md-9">
            <div class="card mb-0" data-aos="zoom-in">
              <div class="h4 text-center title">Contact Me</div>
              <div class="row">
                <div class="col-md-12">
                  <div class="card-body">
                    <p class="mb-0"><strong>Address </strong></p>
                    <p class="pb-2">Paris</p>
                    <p class="mb-0"><strong>Phone</strong></p>
                    <p class="pb-2">+33 (0) 6 507 974 48</p>
                    <p class="mb-0"><strong>Email</strong></p>
                    <p>guezziadil@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div></div>
    </div>
    </div>
)}